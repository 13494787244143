import { parseSelection } from "../advancedOptionHelper";
import FileRepo from "../Dal/FileRepo";
import Job from "./job";
import { STATUS_CONVERTING, STATUS_READY } from "~/fc/Constants/job-status";
import { uuidv4 } from "../helpers";

class Converter {
    constructor(ctx) {
        this.context = ctx;
        this.auth = this.context.$auth;
        this.store = this.context.$store;
        this.info = this.store.state.info;
        this.advancedSetting = this.store.state.advancedSetting;

        this.file = null;
        this.slug = null;
        this.selectedTarget = null;

        this.options = null;
        this.target = null;
        this.importTask = null;
        this.operation = null;
        this.tasks = null;
        this.jobID = null;
        this.itemStatus = STATUS_READY;
        this.autoSaveToDrive = this.store.state.autoSaveToDrive
    }

    setFile(file) {
        this.file = file;
        this.setSelectedTarget();
        this.setSlug();
        return this;
    }

    setSelectedTarget() {
        this.selectedTarget = this.advancedSetting.targetForFile[this.file.id]
            ? this.advancedSetting.targetForFile[this.file.id]
            : { ext: this.file.target };
    }

    setSlug() {
        this.slug = this.info.toolPage
            ? this.info.slug
            : this.file.ext + "-to-" + this.selectedTarget.ext;
    }

    getOptions(full = false) {
        const list = this.advancedSetting.perConversionType?.[this.slug]?.list ?? [];
        const selectedOptions = this.advancedSetting.userData.allSelection[this.advancedSetting.showOnlyRootSettings ? "root" : this.file.id];
        let options = parseSelection(list, selectedOptions, full, true);
        /**
         * In preview pages, we enable root options selection.
         * But some are file-level advanced options.
         * So, we have to combine these options with root options.
         * ref: https://freeconvert.atlassian.net/browse/FC-5883
         */
        if (
            this.advancedSetting.showOnlyRootSettings &&
            ['image_rotation', 'gif_frame_speed', 'image_top_margin', 'image_left_margin'].some(
                key => key in (this.advancedSetting.userData.allSelection[this.file.id] || {})
            )
        ) {
            options.image_rotation = this.advancedSetting.userData.allSelection[this.file.id].image_rotation
            options.gif_frame_speed = this.advancedSetting.userData.allSelection[this.file.id].gif_frame_speed
            options.image_top_margin = this.advancedSetting.userData.allSelection[this.file.id].image_top_margin
            options.image_left_margin = this.advancedSetting.userData.allSelection[this.file.id].image_left_margin
        }

        options = {
            ...options,
            ...(this.advancedSetting.userData.allSelection.finalSelection ?? {}),
            ...(this.info.customVariables?.additionOptions ?? {}),
        };

        return options;
    }

    getTarget() {
        if (this.selectedTarget && this.selectedTarget.slug === 'apng') return 'apng';
        let target = this.selectedTarget.ext || this.file.target;
        if (this.selectedTarget.ext === "Keep same" || this.file.operation === "trim") target = this.file.ext
        if (this.store.state.forceTarget) target = this.store.state.forceTarget
        if (target === "divx" && this.selectedTarget && this.selectedTarget.targetType === "crop-video") target = "avi"

        return target;
    }

    getSource() {
        const isApngPage = ["apng-to-gif", "gif-to-apng"].includes(this.info.slug)
        if (this.file.ext === 'png' && isApngPage) return 'apng'
        return this.file.ext
    }

    getImportTask() {
        if (this.file.import) return null
        if (this.file.file === "url") {
            return this.file.gtoken
                ? {
                    operation: "import/google-drive",
                    fileId: this.file.url,
                    gtoken: this.file.gtoken,
                    filename: this.file.name,
                    filesize: this.file.size,
                }
                : {
                    operation:
                        this.file.ext === "webpage" ? "import/webpage" : "import/url",
                    url: this.file.url,
                    filename: this.file.name ? this.file.name : '',
                };
        }
        if (this.file.serverURL) {
            return {
                operation: "import/url",
                url: this.file.serverURL,
            };
        }
        return {
            operation: "import/upload",
        };
    }

    getOperation() {
        const operation = this.file.operation;
        if (["crop", "trim"].includes(operation)) {
            return "convert";
        }
        return operation;
    }

    async getManipulationTask() {
        const output = this.target && this.target.toLowerCase() === "keep same"
                ? this.file.ext
                : this.target;
        const input = await this.getSource();
        const task = {
            operation: this.operation,
            input: this.file.import || "import",
            input_format: input,
            output_format: output,
            options: this.options
        };

        if (this.store.state.extra) {
            task.extra = this.store.state.extra;
        }

        return task;
    }

    /**
     * This method generates the export tasks needed for the job
     * @returns exportTasks
     */
    getExportTasks() {
        const exportTasks = {
            'export-url': {
                operation: "export/url",
                input: this.operation,
            }
        }
        if (this.autoSaveToDrive) exportTasks['export-google-drive'] = {
            operation: "export/google-drive",
            input: 'export-url',
            token: this.file.gtoken || $nuxt.$store.state.store.googleDriveTokenForUpload,
            filename: this.file.name,
            folder: this.file.folder || 'root'
        }
        return exportTasks
    }

    async getTasks() {
        const manipulationTask = await this.getManipulationTask();
        const exportTasks = this.getExportTasks();
        const job = {
            [this.operation]: manipulationTask,
            ...exportTasks
        }
        if(this.importTask) job['import'] = this.importTask
        return job
    }

    async setVariables(full = false) {
        this.options = this.getOptions(full);
        this.target = this.getTarget();
        this.importTask = this.getImportTask();
        this.operation = this.getOperation();
        this.tasks = await this.getTasks();
        return this.tasks;
    }

    async startConversionJob() {
        const job = new Job(this.tasks, "manipulation");
        try {
            this.jobID = await job.processSingleFile({
                file: this.file,
                jobId: uuidv4(),
                returnJob: false,
            });
            this.saveJobId(this.file, this.jobID);
            this.itemStatus =
                this.jobID && this.store.state.items.jobs[this.jobID]
                    ? this.store.state.items.jobs[this.jobID].status || STATUS_READY
                    : STATUS_READY;
        } catch (error) { }
    }

    saveJobId(file, jobID) {
        new FileRepo(this.context).addJobIDtoFile(file, jobID);
    }

    postConversionInitiationActions() {
        setTimeout(() => {
            if (this.itemStatus < STATUS_CONVERTING) {
                this.context.$gtm.push({ event: "upload_delay_300s" });
            }
        }, 300000);
    }

    async convert() {
        await this.setVariables();
        this.startConversionJob();
        this.postConversionInitiationActions();
    }
}

export default Converter;
